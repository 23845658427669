<template>
  <div class="swap-id bg-white fixed font-bold inline-grid left-10 p-15 top-10">
    <p class="font-bbva version">App version: v{{ version }}</p>

    <label class="my-8 text-15">{{ $t('SWAP_ID.LABEL') }}</label>
    <app-input v-model="employeeId" class="w-[170px]" @[`keyup.enter`]="swapEmployee"></app-input>

    <app-button
      class="bg-green-300 font-bbva mt-5 py-12 w-[170px]"
      :disabled="!employeeId"
      @click="swapEmployee"
    >
      {{ $t('SHARED.CONTINUE') }}
    </app-button>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton'
import AppInput from '@/components/form/AppInput'

export default {
  name: 'AppSwapId',

  components: {
    AppButton,
    AppInput,
  },

  data() {
    return {
      employeeId: undefined,
    }
  },

  computed: {
    version() {
      return process.env.VUE_APP_VERSION
    },
  },

  methods: {
    swapEmployee() {
      this.employeeId && this.$emit('swapEmployee', this.employeeId)
    },
  },
}
</script>

<style lang="scss" scoped>
.swap-id {
  border: 1px solid theme('colors.grey.900');

  .version {
    border-bottom: 1px solid theme('colors.grey.900');
  }
}
</style>
